<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="500px"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Request Payout</div>
        <v-btn icon @click="modalClosed">
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-6" v-if="item">
        <v-form lazy-validation ref="form">
          <v-select
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            :rules="[$rules.required]"
            v-model="form.payout_for"
            :items="payout_for_choices"
            item-text="name"
            item-value="value"
            flat
            outlined
            dense
            label="Payout For"
            :hide-details="showHint"
          ></v-select>

          <div v-if="showHint" class="my-4">
            <v-card outlined>
              <v-card-text class="pa-2 text-caption">
                <div v-if="item[form.payout_for].name">
                  <span class="font-weight-bold">Name:</span>
                  {{ item[form.payout_for].name }}
                </div>
                <div v-if="item[form.payout_for].user.email">
                  <span class="font-weight-bold">Email:</span>
                  {{ item[form.payout_for].user.email }}
                </div>
                <div v-if="item[form.payout_for].user.mobile">
                  <span class="font-weight-bold">Mobile:</span>
                  {{ item[form.payout_for].user.mobile_display }}
                </div>
                <div v-if="item[form.payout_for].account_number">
                  <span class="font-weight-bold">Account Number:</span>
                  {{ item[form.payout_for].account_number }}
                </div>
                <div v-if="item[form.payout_for].upi_id">
                  <span class="font-weight-bold">UPI Id:</span>
                  {{ item[form.payout_for].upi_id }}
                </div>
                <div v-if="item[form.payout_for].bank_name">
                  <span class="font-weight-bold">Bank name:</span>
                  {{ item[form.payout_for].bank_name }}
                </div>
                <div v-if="item[form.payout_for].bank_ifsc_code">
                  <span class="font-weight-bold">IFSC code:</span>
                  {{ item[form.payout_for].bank_ifsc_code }}
                </div>
                <div v-if="item[form.payout_for].bank_branch_name">
                  <span class="font-weight-bold">Branch Name:</span>
                  {{ item[form.payout_for].bank_branch_name }},
                  {{ item[form.payout_for].bank_branch_address }}
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div
            v-if="item.service.type === this.$keys.EDUCATIONAL_DOCUMENTATION"
          >
            <v-checkbox
              v-model="form.is_splitted"
              v-if="form.payout_for == 'fe_ir'"
              label="Split the Amount"
              class="mt-n1"
            ></v-checkbox>

            <div
              class="d-flex"
              v-if="
                form.is_splitted && form.payout_for == 'fe_ir' ? true : false
              "
            >
              <label class="label my-2 pl-1">FE/IR</label>
              <v-text-field
                v-model="form.fe_ir_amount"
                :rules="[
                  $rules.validateNegativeNumber,
                  validateMaxNumber('fe_ir_amount'),
                ]"
                @keydown="$rules.blockInvalidChar"
                @input="validateNegativeNumber('fe_ir_amount')"
                height="42px"
                class="ml-12"
                type="number"
                outlined
                dense
                hide-spin-buttons
                :hint="item.fe_ir ? item.fe_ir.name : ''"
                persistent-hint
              ></v-text-field>
            </div>

            <div
              class="d-flex mb-2"
              v-if="
                form.is_splitted
                  ? form.payout_for == 'fe_ir'
                    ? true
                    : false
                  : false
              "
            >
              <label class="label my-2 pl-1">University</label>
              <v-text-field
                v-model="form.university_amount"
                :rules="[
                  $rules.validateNegativeNumber,
                  validateMaxNumber('university_amount'),
                ]"
                @keydown="$rules.blockInvalidChar"
                @input="validateNegativeNumber('university_amount')"
                height="42px"
                class="ml-5"
                type="number"
                outlined
                dense
                hide-spin-buttons
                :hint="
                  item.university
                    ? `${item.university.name} | ${item.university.city.name}, ${item.university.city.state.name}`
                    : ''
                "
                persistent-hint
              ></v-text-field>
            </div>
          </div>
          <v-text-field
            label="Recipient"
            v-model="form.recipient"
            height="42px"
            :disabled="form.is_splitted"
            type="text"
            outlined
            dense
            hide-spin-buttons
          ></v-text-field>

          <v-text-field
            label="Total Amount"
            v-model="form.total_amount"
            :rules="[
              $rules.validateNegativeNumber,
              validateMaxNumber('total_amount'),
            ]"
            @keydown="$rules.blockInvalidChar"
            @input="validateNegativeNumber('total_amount')"
            height="42px"
            :disabled="form.is_splitted"
            type="number"
            outlined
            dense
            hide-spin-buttons
          ></v-text-field>

          <v-textarea
            rows="3"
            type="text"
            v-model="form.remark"
            flat
            outlined
            dense
            label="Type remarks here..."
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="my-2">
        <v-spacer />
        <v-btn
          color="primary"
          @click="submit"
          class="text-capitalize px-8"
          :loading="btnLoading"
        >
          Request Payout
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      btnLoading: false,
      form: {
        payout_for: "",
        is_splitted: false,
        fe_ir_amount: 0,
        university_amount: 0,
        total_amount: 0,
        remark: "",
        recipient:'',
      },
    };
  },
  computed: {
    ...mapGetters({
      getPayoutForm: "caseManagement/getPayoutForm",
    }),

    show: {
      get() {
        return this.getPayoutForm.show;
      },
      set() {},
    },

    item() {
      return this.getPayoutForm.item;
    },

    payout_for_choices() {
      const choices = [];

      if (this.item.service.type === this.$keys.EDUCATIONAL_DOCUMENTATION) {
        choices.push({ name: "FE/IR", value: "fe_ir" });
        choices.push({ name: "University", value: "university" });
      }

      if (
        [this.$keys.APOSTILLE_TRANSLATIONS, this.$keys.CERTIFICATIONS].includes(
          this.item.service.type
        )
      ) {
        choices.push({ name: "Vendor", value: "vendor", selected: true });
      }

      choices.push({ name: "Client (Refund)", value: "client" });
      choices.push({ name: "Shipping", value: "shipping" });
      return choices;
    },

    showHint() {
      if (this.form.payout_for === "fe_ir" && this.item.fe_ir) return true;
      if (this.form.payout_for === "vendor" && this.item.vendor) return true;
      if (this.form.payout_for === "university" && this.item.university)
        return true;
      return false;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },

    "form.payout_for"(value) {
      if (value === "fe_ir") {
        this.form.total_amount = this.item.university_additional_cost;
      } else if (value === "university") {
        this.form.is_splitted = false;
        this.form.total_amount = this.item.university_cost;
      } else if (value === "shipping") {
        this.form.total_amount = this.item.shipping_cost;
      } else {
        this.form.is_splitted = false;
        this.form.total_amount = 0;
      }
    },

    "form.is_splitted"(value) {
      this.form.is_splitted = value;
      if (value) {
        this.form.fe_ir_amount = this.item.university_additional_cost;
        this.form.university_amount = this.item.university_cost;
        this.form.total_amount =
          Number(this.form.fe_ir_amount) + Number(this.form.university_amount);
      } else {
        if (this.form.payout_for === "fe_ir") {
          this.form.total_amount = this.item.university_additional_cost;
        }
      }
    },

    "form.fe_ir_amount"() {
      this.form.total_amount =
        Number(this.form.fe_ir_amount) + Number(this.form.university_amount);
    },

    "form.university_amount"() {
      this.form.total_amount =
        Number(this.form.fe_ir_amount) + Number(this.form.university_amount);
    },
  },
  methods: {
    ...mapActions({
      payoutForm: "caseManagement/payoutForm",
      showSnackbar: "snackBar/showSnackbar",
    }),

    openModal() {},

    modalClosed() {
      this.payoutForm({ item: null, show: false });
      this.form = {
        payout_for: "fe_ir",
        is_splitted: false,
        fe_ir_amount: 0,
        university_amount: 0,
        total_amount: 0,
        remark: "",
      };
    },

    validateNegativeNumber(field) {
      let value = this.form[field];
      value = Number(value);

      if (value < 0) {
        this.form[field] = Number(0);
      } else this.form[field] = value;
    },

    validateMaxNumber() {
      // let value = this.form[field];
      // value = Number(value);

      // if (this.item) {
      //   const data = this.item;
      //   if (field === "fe_ir_amount") {
      //     if (value > data.university_additional_cost)
      //       return `FE/IR amount cannot be greater than ${data.university_additional_cost}`;
      //   }
      //   if (field === "university_amount") {
      //     if (value > data.university_cost)
      //       return `University amount cannot be greater than ${data.university_cost}`;
      //   }
      //   if (field === "total_amount") {
      //     if (this.form.is_splitted) return true;

      //     if (this.form.payout_for === "fe_ir") {
      //       if (value > data.university_additional_cost)
      //         return `Amount cannot be greater than ${data.university_additional_cost}`;
      //     }
      //     if (this.form.payout_for === "university") {
      //       if (value > data.university_cost)
      //         return `Amount cannot be greater than ${data.university_cost}`;
      //     }
      //     if (this.form.payout_for === "shipping") {
      //       if (value > data.shipping_cost)
      //         return `Amount cannot be greater than ${data.shipping_cost}`;
      //     }
      //   }
      // }
      return true;
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        if (this.form.total_amount <= 0) {
          this.showSnackbar({
            text: "Can not request without amount.",
            color: "error",
          });
          this.btnLoading = false;
          return;
        }

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = {
          case: this.getPayoutForm.item.id,
          amount: this.form.total_amount,
          remarks: this.form.remark,
          payout_receiver : this.form.recipient
        };

        data["payout_for"] = this.form.payout_for;

        if (this.form.payout_for == "fe_ir") {
          data["is_splitted"] = this.form.is_splitted;
          if (this.form.university_amount || this.form.fe_ir_amount) {
            if (this.form.university_amount)
              data["university_amount"] = this.form.university_amount;
            else data["university_amount"] = 0;
            data["fe_ir_amount"] = this.form.fe_ir_amount;
          }
        }

        return this.$request(this.$keys.POST, this.$urls.case.payout.create, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>
<style scoped>
.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
</style>